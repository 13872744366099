document.addEventListener('DOMContentLoaded', function(event) {
    if (window.innerWidth > 1199) {
        VanillaTilt.init(document.querySelector('.js-manufacture__image-tilt'), {
            reverse: true,
            scale: 1.05,
            max: 10,
        });
    }

    if (window.innerWidth > 1199) {
        VanillaTilt.init(document.querySelector('.js-first-screen__image'), {
            reverse: true,
            scale: 1.1,
            max: 15,
            axis: "x",
        });
    }

    var productionSlider = document.querySelector('.js-production-main__slider');

    if(productionSlider != null) {
        var flktyProductionSlider = new Flickity(productionSlider, {
            prevNextButtons: false,
            pageDots: true,
            draggable: false,
            wrapAround: true,
            on: {
                ready: function () {
                    var flickityDots = document.querySelector('.js-production-main__slider .flickity-page-dots'),
                        link = document.querySelector('.js-production-main__link');
                    flickityDots.appendChild(link);
                }
            }
        });
    }

    var servicesSlider = document.querySelector('.js-services-main__slider');

    if(servicesSlider != null) {
        var flktyservicesSlider = new Flickity(servicesSlider, {
            prevNextButtons: false,
            pageDots: true,
            draggable: false,
            wrapAround: true,
            on: {
                ready: function () {
                    var flickityDots = document.querySelector('.js-services-main__slider .flickity-page-dots'),
                        link = document.querySelector('.js-services-main__link');
                    flickityDots.appendChild(link);
                }
            }
        });
    }
});
