"use strict";

document.addEventListener('DOMContentLoaded', function (event) {});
"use strict";

document.addEventListener("DOMContentLoaded", function () {
  var tourItems = document.querySelectorAll('.tour__list-item'),
      tourSlider = document.querySelector('.js-tour__slider'),
      tourSliderButtonNext = document.querySelector('.tour__nav .next'),
      tourSliderButtonPrev = document.querySelector('.tour__nav .prev'),
      infoModalOpen = document.querySelector('.tour__nav .info'),
      infoModalClose = document.querySelector('.tour__nav .info-modal__close'),
      tourClose = document.querySelector('.tour__panorama-close');

  if (tourItems.length) {
    ymaps.ready(function () {
      var panoramaWrap = document.querySelector('.tour__panorama');
      var panoramaData = {
        first: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Зона погрузки разгрузки',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/1/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/1/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'second',
            direction: [0, 0]
          }]
        },
        second: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Цех металлообработки',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/2/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/2/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'first',
            direction: [3.14159, 0]
          }, {
            panoID: 'fifth',
            direction: [-1.5707, 0]
          }]
        },
        third: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'test3',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/3/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/3/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'seventeenth',
            direction: [-1.5707, 0]
          }, {
            panoID: 'sixteenth',
            direction: [-2, 0]
          }, {
            panoID: 'fifteenth',
            direction: [2.513, 0]
          }, {
            panoID: 'fourteenth',
            direction: [3.14, 0]
          }, {
            panoID: 'fifth',
            direction: [-2.513, 0]
          }]
        },
        fourth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Отдел технического контроля',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/4/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/4/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'thirteenth',
            direction: [1.5707, 0]
          }]
        },
        fifth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Слесарный цех',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/5/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/5/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'second',
            direction: [1.5707, 0]
          }, {
            panoID: 'seventh',
            direction: [-1.5707, 0]
          }, {
            panoID: 'fourteenth',
            direction: [-0.7853, 0]
          }, {
            panoID: 'fifteenth',
            direction: [0.3926, 0]
          }, {
            panoID: 'sixteenth',
            direction: [0.7853, 0]
          }, {
            panoID: 'third',
            direction: [1.1779, 0]
          }]
        },
        sixth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Цех металлообработки 1',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/6/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/6/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'eighth',
            direction: [3.14159, 0]
          }, {
            panoID: 'seventh',
            direction: [-1.5707, 0]
          }, {
            panoID: 'ninth',
            direction: [0, 0]
          }]
        },
        seventh: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Слесарный цех 2',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/7/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/7/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'fifth',
            direction: [3.14159, 0]
          }, {
            panoID: 'sixth',
            direction: [0, 0]
          }, {
            panoID: 'thirteenth',
            direction: [-1.5707, 0]
          }]
        },
        eighth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Цех металлообработки 1_2',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/8/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/8/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'sixth',
            direction: [-1.5707, 0]
          }]
        },
        ninth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Цех металлообработки 1_3',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/9/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/9/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'sixth',
            direction: [-1.5707, 0]
          }]
        },
        tenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Электромонтажный цех',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/10/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/10/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'twelfth',
            direction: [0, 0]
          }]
        },
        eleventh: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Электромонтажный цех 2',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/11/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/11/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'twelfth',
            direction: [0, 0]
          }]
        },
        twelfth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Электромонтажный цех 3',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/12/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/12/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'thirteenth',
            direction: [-1.5707, 0]
          }, {
            panoID: 'tenth',
            direction: [3.14, 0]
          }, {
            panoID: 'eleventh',
            direction: [0, 0]
          }]
        },
        thirteenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'test13',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/13/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/13/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'seventh',
            direction: [-1.5707, 0]
          }, {
            panoID: 'twelfth',
            direction: [3.14, 0]
          }, {
            panoID: 'fourth',
            direction: [0, 0]
          }]
        },
        fourteenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'test14',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/14/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/14/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'fifteenth',
            direction: [0, 0]
          }, {
            panoID: 'sixteenth',
            direction: [0.3, 0]
          }, {
            panoID: 'third',
            direction: [0.7, 0]
          }, {
            panoID: 'fifth',
            direction: [2.3, 0]
          }]
        },
        fifteenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'test15',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/15/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/15/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'sixteenth',
            direction: [0, 0]
          }, {
            panoID: 'third',
            direction: [0.3, 0]
          }, {
            panoID: 'fifth',
            direction: [1.8, 0]
          }, {
            panoID: 'fourteenth',
            direction: [3.14, 0]
          }]
        },
        sixteenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Участок SMD-компонентов',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/16/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/16/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'fifteenth',
            direction: [-1.5707, 0]
          }, {
            panoID: 'third',
            direction: [1.5707, 0]
          }, {
            panoID: 'fourteenth',
            direction: [-2.3, 0]
          }, {
            panoID: 'fifth',
            direction: [-0.8, 0]
          }]
        },
        seventeenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Лаборатория метрологического обеспечения',
          tileLevels: [{
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/17/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [256, 128];
            }
          }, {
            getTileUrl: function getTileUrl(x, y) {
              return '../images/3dtour/17/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function getImageSize() {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'third',
            direction: [0.3926, 0]
          }]
        }
      };

      function addTourName() {
        document.querySelectorAll('[data-tour]').forEach(function (tourItem) {
          var tourName = tourItem.querySelector('.tour__list-item-name').textContent;
          var panaramaItem = panoramaData[Object.keys(panoramaData)[tourItem.dataset.tour - 1]];
          panaramaItem.name = tourName;
        });
      }

      addTourName();

      function getConnectedPanoramaData(panoID) {
        return panoramaData[panoID];
      }

      function ConnectionArrow(currentPanorama, direction, nextPanorama) {
        this.properties = new ymaps.data.Manager();
        this._currentPanorama = currentPanorama;
        this._direction = direction;
        this._connectedPanorama = nextPanorama;
      }

      ymaps.util.defineClass(ConnectionArrow, {
        getConnectedPanorama: function getConnectedPanorama() {
          return ymaps.vow.resolve(new myPanorama(this._connectedPanorama));
        },
        // Направление взгляда на панораму, на которую будет осуществляться переход.
        getDirection: function getDirection() {
          return this._direction;
        },
        // Ссылка на текущую панораму, из которой осуществляется переход.
        getPanorama: function getPanorama() {
          return this._currentPanorama;
        }
      });

      function myPanorama(obj) {
        ymaps.panorama.Base.call(this);
        this._angularBBox = obj.angularBBox;
        this._position = obj.position;
        this._tileSize = obj.tileSize;
        this._tileLevels = obj.tileLevels;
        this._name = obj.name; // Получаем массив экземпляров класса, описывающего переход по стрелке из
        // одной панорамы на другую.

        this._connectionArrows = obj.connectionArrows.map(function (connectionArrow) {
          return new ConnectionArrow(this, // Текущая панорама.
          connectionArrow.direction, // Направление взгляда на панораму, на которую делаем переход.
          getConnectedPanoramaData(connectionArrow.panoID) // Данные панорамы, на которую делаем переход.
          );
        }, this);
      }

      ymaps.util.defineClass(myPanorama, ymaps.panorama.Base, {
        // Чтобы добавить на панораму стандартные стрелки переходов,
        // реализуем метод getConnectionArrows.
        getConnectionArrows: function getConnectionArrows() {
          return this._connectionArrows;
        },
        getAngularBBox: function getAngularBBox() {
          return this._angularBBox;
        },
        getPosition: function getPosition() {
          return this._position;
        },
        getTileSize: function getTileSize() {
          return this._tileSize;
        },
        getTileLevels: function getTileLevels() {
          return this._tileLevels;
        },
        getCoordSystem: function getCoordSystem() {
          return ymaps.coordSystem.cartesian;
        }
      });
      var panorama = new myPanorama(panoramaData.first);
      var player = new ymaps.panorama.Player(panoramaWrap, panorama);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        var _loop = function _loop() {
          var tourItem = _step.value;
          tourItem.addEventListener('mouseover', function () {
            var itemWidth = 20,
                horizontalOffset = 95,
                verticalOffset = 5;
            var itemName = this.querySelector('.tour__list-item-name');
            itemName.classList.remove('r-b', 'r-t', 'l-b', 'l-t');
            var itemNameOffset = itemName.getBoundingClientRect(),
                itemNameWidth = itemName.offsetWidth,
                itemNameHeight = itemName.offsetHeight,
                itemNameOffsetLeft = itemNameOffset.left - itemNameWidth - itemWidth - horizontalOffset,
                itemNameOffsetRight = itemNameOffset.right - itemNameWidth - itemWidth - horizontalOffset,
                itemNameOffsetTop = itemNameOffset.top - itemNameHeight - itemWidth - verticalOffset,
                itemNameOffsetBottom = itemNameOffset.bottom - itemNameHeight - itemWidth - verticalOffset,
                itemClass = '';

            if (itemNameOffset.left > 1200) {
              itemClass = 'l-t';
            } else {
              itemClass = 'r-b';
            }

            itemName.classList.add(itemClass);
          });
          var panoramaNameContainer = document.querySelector(".tour__panorama-name");
          tourItem.addEventListener('click', function (evt) {
            var panoramaContainer = document.querySelector('.tour__panorama'),
                headerBurger = document.querySelector('.header_burger'),
                tourId = this.getAttribute("data-tour"),
                panoramaList = Object.values(panoramaData);
            player.destroy();
            panorama = new myPanorama(panoramaList[tourId - 1]);
            player = new ymaps.panorama.Player(panoramaWrap, panorama);
            panoramaContainer.classList.add('active');
            headerBurger.classList.add('hidden');
            var panoramaName = player._engine._panorama._name;
            panoramaNameContainer.textContent = panoramaName;
            player.events.add('panoramachange', function () {
              panoramaName = player._engine._panorama._name;
              panoramaNameContainer.textContent = panoramaName;
            });
          });
        };

        for (var _iterator = tourItems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          _loop();
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator["return"] != null) {
            _iterator["return"]();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    });
    tourClose.addEventListener('click', function () {
      var panorama = document.querySelector('.tour__panorama'),
          headerBurger = document.querySelector('.header_burger');
      panorama.classList.remove('active');
      headerBurger.classList.remove('hidden');
    });
    infoModalOpen.addEventListener('click', function () {
      var infoModal = document.querySelector('.tour__nav .info-modal');
      infoModal.classList.add('active');
    });
    infoModalClose.addEventListener('click', function () {
      var infoModal = document.querySelector('.tour__nav .info-modal');
      infoModal.classList.remove('active');
    });
  }
});
"use strict";

document.addEventListener("DOMContentLoaded", function () {
  var aboutSliderWrap = document.querySelector('.js-about-fs__slider'),
      sliderButtonOpen = document.querySelector('.js-about-fs__link'),
      sliderButtonClose = document.querySelector('.js-about-fs__slider-close'),
      aboutWrap = document.querySelector('.js-about-fs'),
      powerSlider = document.querySelector('.about-power__list');

  if (sliderButtonOpen != null) {
    sliderButtonOpen.addEventListener('click', function (event) {
      event.preventDefault();
      aboutWrap.classList.add('slider-opened');
      return false;
    });
  }

  if (window.innerWidth <= 1199 && powerSlider != null) {
    var flktyPowerSlider = new Flickity(powerSlider, {
      prevNextButtons: false,
      pageDots: true,
      draggable: true,
      wrapAround: true
    });
  }

  if (sliderButtonClose != null) {
    sliderButtonClose.addEventListener('click', function (event) {
      event.preventDefault();
      aboutWrap.classList.remove('slider-opened');
      return false;
    });
  }

  if (aboutSliderWrap != null) {
    var sliderButtonNext = document.querySelector('.about-fs__slider-buttons .next'),
        sliderButtonPrev = document.querySelector('.about-fs__slider-buttons .prev'),
        aboutSlider = new Flickity(aboutSliderWrap, {
      prevNextButtons: false,
      pageDots: true,
      draggable: true,
      wrapAround: true,
      on: {
        ready: function ready() {
          sliderButtonNext.addEventListener('click', function (event) {
            event.preventDefault();
            aboutSlider.next();
            return false;
          });
          sliderButtonPrev.addEventListener('click', function (event) {
            event.preventDefault();
            aboutSlider.previous();
            return false;
          });
        }
      }
    });
  }
});
"use strict";

document.addEventListener("DOMContentLoaded", function () {
  var catalogSliders = document.querySelectorAll('.js-catalog-item__slider'),
      catalogModalWrap = document.getElementById('get-offer'),
      catalogModal = new Modal(catalogModalWrap, {
    backdrop: 'static'
  }),
      modalTriggers = document.querySelectorAll('a[data-toggle="modal"][data-target="get-offer"]'),
      modalInputs = document.querySelectorAll('.get-offer input');
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    var _loop = function _loop() {
      var catalogSlider = _step.value;
      var nextButton = catalogSlider.parentNode.querySelector('.catalog-item__slider-buttons > .next'),
          prevButton = catalogSlider.parentNode.querySelector('.catalog-item__slider-buttons > .prev'),
          flktySlider = new Flickity(catalogSlider, {
        prevNextButtons: false,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        on: {
          ready: function ready() {
            nextButton.addEventListener('click', function (event) {
              event.preventDefault();
              flktySlider.next();
              return false;
            });
            prevButton.addEventListener('click', function (event) {
              event.preventDefault();
              flktySlider.previous();
              return false;
            });
          }
        }
      });
    };

    for (var _iterator = catalogSliders[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      _loop();
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator["return"] != null) {
        _iterator["return"]();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  var _iteratorNormalCompletion2 = true;
  var _didIteratorError2 = false;
  var _iteratorError2 = undefined;

  try {
    for (var _iterator2 = modalTriggers[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
      var modalTrigger = _step2.value;
      modalTrigger.addEventListener('click', function (event) {
        catalogModal.show();
        return false;
      });
    }
  } catch (err) {
    _didIteratorError2 = true;
    _iteratorError2 = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion2 && _iterator2["return"] != null) {
        _iterator2["return"]();
      }
    } finally {
      if (_didIteratorError2) {
        throw _iteratorError2;
      }
    }
  }

  var _iteratorNormalCompletion3 = true;
  var _didIteratorError3 = false;
  var _iteratorError3 = undefined;

  try {
    for (var _iterator3 = modalInputs[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
      var modalInput = _step3.value;
      modalInput.addEventListener('change', function () {
        var parentElement = this.parentElement,
            inputLabel = parentElement.querySelector('label');

        if (this.value != '') {
          inputLabel.classList.add('active');
        } else {
          inputLabel.classList.remove('active');
        }
      });
    }
  } catch (err) {
    _didIteratorError3 = true;
    _iteratorError3 = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion3 && _iterator3["return"] != null) {
        _iterator3["return"]();
      }
    } finally {
      if (_didIteratorError3) {
        throw _iteratorError3;
      }
    }
  }
});
"use strict";

document.addEventListener('DOMContentLoaded', function (event) {
  if (window.innerWidth > 1199) {
    VanillaTilt.init(document.querySelector('.js-manufacture__image-tilt'), {
      reverse: true,
      scale: 1.05,
      max: 10
    });
  }

  if (window.innerWidth > 1199) {
    VanillaTilt.init(document.querySelector('.js-first-screen__image'), {
      reverse: true,
      scale: 1.1,
      max: 15,
      axis: "x"
    });
  }

  var productionSlider = document.querySelector('.js-production-main__slider');

  if (productionSlider != null) {
    var flktyProductionSlider = new Flickity(productionSlider, {
      prevNextButtons: false,
      pageDots: true,
      draggable: false,
      wrapAround: true,
      on: {
        ready: function ready() {
          var flickityDots = document.querySelector('.js-production-main__slider .flickity-page-dots'),
              link = document.querySelector('.js-production-main__link');
          flickityDots.appendChild(link);
        }
      }
    });
  }

  var servicesSlider = document.querySelector('.js-services-main__slider');

  if (servicesSlider != null) {
    var flktyservicesSlider = new Flickity(servicesSlider, {
      prevNextButtons: false,
      pageDots: true,
      draggable: false,
      wrapAround: true,
      on: {
        ready: function ready() {
          var flickityDots = document.querySelector('.js-services-main__slider .flickity-page-dots'),
              link = document.querySelector('.js-services-main__link');
          flickityDots.appendChild(link);
        }
      }
    });
  }
});
"use strict";

document.addEventListener('DOMContentLoaded', function (event) {
  var tabsNav = document.querySelectorAll('.js-production__inner-nav'),
      tabList = document.querySelector('.production-inner .production-catalog__list');

  if (window.innerWidth <= 580) {
    tabList.addEventListener('click', function () {
      this.classList.toggle('opened');
    });
  }

  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = tabsNav[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var tabNav = _step.value;
      tabNav.addEventListener('click', function () {
        var tabTitle = this.getAttribute('data-nav'),
            tab = document.querySelector('[data-tab="' + tabTitle + '"]'),
            activeNav = document.querySelector('.js-production__inner-nav.active'),
            activeTab = document.querySelector('.js-production-inner__tab.active');
        activeNav.classList.remove('active');
        activeTab.classList.remove('active');
        this.classList.add('active');
        tab.classList.add('active');

        if (window.innerWidth <= 580) {
          var mainTab = tabList.querySelector('.js-production-catalog__item-main'),
              tabText = this.querySelector('.production-catalog__item-name').innerText;
          mainTab.innerText = tabText;
        }
      });
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator["return"] != null) {
        _iterator["return"]();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
});
"use strict";

var keys = [32, 33, 34, 35, 36, 37, 38, 39, 40];

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault) e.preventDefault();
  e.returnValue = false;
}

function keydown(e) {
  for (var i = keys.length; i--;) {
    if (e.keyCode === keys[i]) {
      preventDefault(e);
      return;
    }
  }
}

function wheel(e) {
  preventDefault(e);
}

function disable_scroll() {
  // if (window.addEventListener) {
  //   window.addEventListener('DOMMouseScroll', wheel, false);
  // }
  // window.onmousewheel = document.onmousewheel = wheel;
  // document.onkeydown = keydown;
  // disable_scroll_mobile();
  document.querySelector('body').style.overflowY = 'hidden';
}

function enable_scroll() {
  // if (window.removeEventListener) {
  //   window.removeEventListener('DOMMouseScroll', wheel, false);
  // }
  // window.onmousewheel = document.onmousewheel = document.onkeydown = null;
  // enable_scroll_mobile();
  document.querySelector('body').style.overflowY = 'scroll';
}

function disable_scroll_mobile() {
  document.addEventListener('touchmove', preventDefault, false);
}

function enable_scroll_mobile() {
  document.removeEventListener('touchmove', preventDefault, false);
}

document.addEventListener('DOMContentLoaded', function (event) {
  var burgerButton = document.querySelector('.js-header__burger'),
      container = document.querySelector('.js-content');
  window.addEventListener('scroll', function () {
    if (window.scrollY > 0) {
      document.querySelector('.js-header').classList.add('active');
      document.querySelector('.header_burger').classList.add('active');
    } else {
      document.querySelector('.js-header').classList.remove('active');
      document.querySelector('.header_burger').classList.remove('active');
    }
  });
  burgerButton.addEventListener('click', function () {
    if (window.innerWidth > 1199) {
      var windowWidth = window.innerWidth,
          bodyOffsetTop = document.querySelector('body').getBoundingClientRect().top;
      var tl = new TimelineMax({
        onComplete: function onComplete() {
          new TimelineMax();
        }
      });

      if (this.classList.contains('is-active')) {
        this.classList.remove('is-active');
        var currentScreen = document.querySelector('.js-menu'),
            newScreen = document.querySelector('.js-content-wrap'),
            header = document.querySelector('.js-header');
        windowWidth = -windowWidth;
        var my = tl.set(newScreen, {
          zIndex: 100
        }).set(currentScreen, {
          zIndex: 100
        }).fromTo(currentScreen, 0.5, {
          x: 0
        }, {
          x: -windowWidth
        }, 0).fromTo(newScreen, 0.5, {
          x: windowWidth
        }, {
          x: 0
        }, 0).fromTo(header, 0, {
          y: 0
        }, {
          y: -bodyOffsetTop
        }, 0).set(currentScreen, {
          zIndex: 'auto'
        });
        setTimeout(function () {
          newScreen.style.transform = '';
          header.style.transform = '';
          enable_scroll();
        }, 600);
      } else {
        this.classList.add('is-active');
        var currentScreen = document.querySelector('.js-content-wrap'),
            newScreen = document.querySelector('.js-menu');
        disable_scroll();
        var my = tl.set(newScreen, {
          zIndex: 100
        }).set(currentScreen, {
          zIndex: 100
        }).fromTo(newScreen, 0, {
          y: 0
        }, {
          y: -bodyOffsetTop
        }).fromTo(currentScreen, 0.5, {
          x: 0
        }, {
          x: -windowWidth
        }, 0).fromTo(newScreen, 0.5, {
          x: windowWidth
        }, {
          x: 0
        }, 0).set(currentScreen, {
          zIndex: 'auto'
        });
      }
    } else {
      var menu = document.querySelector('.js-menu'),
          body = document.querySelector('body');
      menu.classList.toggle('active');
      body.classList.toggle('overflow');
    }
  });
});