document.addEventListener("DOMContentLoaded", function () {
  let tourItems = document.querySelectorAll('.tour__list-item'),
    tourSlider = document.querySelector('.js-tour__slider'),
    tourSliderButtonNext = document.querySelector('.tour__nav .next'),
    tourSliderButtonPrev = document.querySelector('.tour__nav .prev'),
    infoModalOpen = document.querySelector('.tour__nav .info'),
    infoModalClose = document.querySelector('.tour__nav .info-modal__close'),
    tourClose = document.querySelector('.tour__panorama-close');


  if (tourItems.length) {
    ymaps.ready(function () {
      let panoramaWrap = document.querySelector('.tour__panorama');

      let panoramaData = {
        first: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Зона погрузки разгрузки',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/1/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/1/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'second',
            direction: [0, 0]
          }]
        },
        second: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Цех металлообработки',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/2/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/2/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'first',
            direction: [3.14159, 0]
          }, {
            panoID: 'fifth',
            direction: [-1.5707, 0]
          }]
        },
        third: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'test3',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/3/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/3/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'seventeenth',
            direction: [-1.5707, 0]
          }, {
            panoID: 'sixteenth',
            direction: [-2, 0]
          }, {
            panoID: 'fifteenth',
            direction: [2.513, 0]
          }, {
            panoID: 'fourteenth',
            direction: [3.14, 0]
          }, {
            panoID: 'fifth',
            direction: [-2.513, 0]
          }]
        },
        fourth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Отдел технического контроля',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/4/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/4/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'thirteenth',
            direction: [1.5707, 0]
          }]
        },
        fifth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Слесарный цех',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/5/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/5/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'second',
            direction: [1.5707, 0]
          }, {
            panoID: 'seventh',
            direction: [-1.5707, 0]
          }, {
            panoID: 'fourteenth',
            direction: [-0.7853, 0]
          }, {
            panoID: 'fifteenth',
            direction: [0.3926, 0]
          }, {
            panoID: 'sixteenth',
            direction: [0.7853, 0]
          }, {
            panoID: 'third',
            direction: [1.1779, 0]
          }]
        },
        sixth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Цех металлообработки 1',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/6/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/6/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'eighth',
            direction: [3.14159, 0]
          }, {
            panoID: 'seventh',
            direction: [-1.5707, 0]
          }, {
            panoID: 'ninth',
            direction: [0, 0]
          }]
        },
        seventh: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Слесарный цех 2',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/7/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/7/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'fifth',
            direction: [3.14159, 0]
          }, {
            panoID: 'sixth',
            direction: [0, 0]
          }, {
            panoID: 'thirteenth',
            direction: [-1.5707, 0]
          }]
        },
        eighth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Цех металлообработки 1_2',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/8/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/8/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'sixth',
            direction: [-1.5707, 0]
          }]
        },
        ninth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Цех металлообработки 1_3',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/9/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/9/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'sixth',
            direction: [-1.5707, 0]
          }]
        },
        tenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Электромонтажный цех',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/10/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/10/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'twelfth',
            direction: [0, 0]
          }]
        },
        eleventh: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Электромонтажный цех 2',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/11/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/11/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'twelfth',
            direction: [0, 0]
          }]
        },
        twelfth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Электромонтажный цех 3',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/12/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/12/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'thirteenth',
            direction: [-1.5707, 0]
          }, {
            panoID: 'tenth',
            direction: [3.14, 0]
          }, {
            panoID: 'eleventh',
            direction: [0, 0]
          }]
        },
        thirteenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'test13',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/13/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/13/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'seventh',
            direction: [-1.5707, 0]
          }, {
            panoID: 'twelfth',
            direction: [3.14, 0]
          }, {
            panoID: 'fourth',
            direction: [0, 0]
          }]
        },
        fourteenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'test14',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/14/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/14/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'fifteenth',
            direction: [0, 0]
          }, {
            panoID: 'sixteenth',
            direction: [0.3, 0]
          }, {
            panoID: 'third',
            direction: [0.7, 0]
          }, {
            panoID: 'fifth',
            direction: [2.3, 0]
          }]
        },
        fifteenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'test15',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/15/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/15/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'sixteenth',
            direction: [0, 0]
          }, {
            panoID: 'third',
            direction: [0.3, 0]
          }, {
            panoID: 'fifth',
            direction: [1.8, 0]
          }, {
            panoID: 'fourteenth',
            direction: [3.14, 0]
          }]
        },
        sixteenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Участок SMD-компонентов',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/16/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/16/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'fifteenth',
            direction: [-1.5707, 0]
          }, {
            panoID: 'third',
            direction: [1.5707, 0]
          }, {
            panoID: 'fourteenth',
            direction: [-2.3, 0]
          }, {
            panoID: 'fifth',
            direction: [-0.8, 0]
          }]
        },
        seventeenth: {
          angularBBox: [0.5 * Math.PI, 2 * Math.PI, -0.5 * Math.PI, 0],
          position: [0, 0, 0],
          tileSize: [256, 128],
          name: 'Лаборатория метрологического обеспечения',
          tileLevels: [{
            getTileUrl: function (x, y) {
              return '../images/3dtour/17/tiles-lq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [256, 128];
            }
          }, {
            getTileUrl: function (x, y) {
              return '../images/3dtour/17/tiles-hq/' + x + '-' + y + '.jpg';
            },
            getImageSize: function () {
              return [5376, 2688];
            }
          }],
          connectionArrows: [{
            panoID: 'third',
            direction: [0.3926, 0]
          }]
        }
      };

      function addTourName() {
        document.querySelectorAll('[data-tour]').forEach(tourItem => {
          let tourName = tourItem.querySelector('.tour__list-item-name').textContent;
          let panaramaItem = panoramaData[Object.keys(panoramaData)[tourItem.dataset.tour - 1]];
          panaramaItem.name = tourName;
        });
      }

      addTourName();

      function getConnectedPanoramaData(panoID) {
        return panoramaData[panoID];
      }

      function ConnectionArrow(currentPanorama, direction, nextPanorama) {
        this.properties = new ymaps.data.Manager();
        this._currentPanorama = currentPanorama;
        this._direction = direction;
        this._connectedPanorama = nextPanorama;
      }

      ymaps.util.defineClass(ConnectionArrow, {
        getConnectedPanorama: function () {
          return ymaps.vow.resolve(new myPanorama(this._connectedPanorama));
        },
        // Направление взгляда на панораму, на которую будет осуществляться переход.
        getDirection: function () {
          return this._direction;
        },
        // Ссылка на текущую панораму, из которой осуществляется переход.
        getPanorama: function () {
          return this._currentPanorama;
        }
      });

      function myPanorama(obj) {
        ymaps.panorama.Base.call(this);
        this._angularBBox = obj.angularBBox;
        this._position = obj.position;
        this._tileSize = obj.tileSize;
        this._tileLevels = obj.tileLevels;
        this._name = obj.name;
        // Получаем массив экземпляров класса, описывающего переход по стрелке из
        // одной панорамы на другую.
        this._connectionArrows = obj.connectionArrows.map(function (connectionArrow) {
          return new ConnectionArrow(
            this, // Текущая панорама.
            connectionArrow.direction, // Направление взгляда на панораму, на которую делаем переход.
            getConnectedPanoramaData(connectionArrow.panoID) // Данные панорамы, на которую делаем переход.
          );
        }, this);
      }

      ymaps.util.defineClass(myPanorama, ymaps.panorama.Base, {
        // Чтобы добавить на панораму стандартные стрелки переходов,
        // реализуем метод getConnectionArrows.
        getConnectionArrows: function () {
          return this._connectionArrows;
        },
        getAngularBBox: function () {
          return this._angularBBox;
        },
        getPosition: function () {
          return this._position;
        },
        getTileSize: function () {
          return this._tileSize;
        },
        getTileLevels: function () {
          return this._tileLevels;
        },
        getCoordSystem: function () {
          return ymaps.coordSystem.cartesian;
        }
      });

      let panorama = new myPanorama(panoramaData.first);

      let player = new ymaps.panorama.Player(panoramaWrap, panorama);

      for (let tourItem of tourItems) {
        tourItem.addEventListener('mouseover', function () {
          const itemWidth = 20,
            horizontalOffset = 95,
            verticalOffset = 5;
          let itemName = this.querySelector('.tour__list-item-name');

          itemName.classList.remove('r-b', 'r-t', 'l-b', 'l-t');

          let itemNameOffset = itemName.getBoundingClientRect(),
            itemNameWidth = itemName.offsetWidth,
            itemNameHeight = itemName.offsetHeight,
            itemNameOffsetLeft = itemNameOffset.left - itemNameWidth - itemWidth - horizontalOffset,
            itemNameOffsetRight = itemNameOffset.right - itemNameWidth - itemWidth - horizontalOffset,
            itemNameOffsetTop = itemNameOffset.top - itemNameHeight - itemWidth - verticalOffset,
            itemNameOffsetBottom = itemNameOffset.bottom - itemNameHeight - itemWidth - verticalOffset,
            itemClass = '';

          if (itemNameOffset.left > 1200) {
            itemClass = 'l-t';
          } else {
            itemClass = 'r-b';
          }

          itemName.classList.add(itemClass);
        });

        let panoramaNameContainer = document.querySelector(".tour__panorama-name");

        tourItem.addEventListener('click', function (evt) {
          let panoramaContainer = document.querySelector('.tour__panorama'),
            headerBurger = document.querySelector('.header_burger'),
            tourId = this.getAttribute("data-tour"),
            panoramaList = Object.values(panoramaData);
          player.destroy();

          panorama = new myPanorama(panoramaList[tourId - 1]);
          player = new ymaps.panorama.Player(panoramaWrap, panorama);

          panoramaContainer.classList.add('active');
          headerBurger.classList.add('hidden');

          let panoramaName = player._engine._panorama._name;

          panoramaNameContainer.textContent = panoramaName;

          player.events.add('panoramachange', function () {
            panoramaName = player._engine._panorama._name;
            panoramaNameContainer.textContent = panoramaName;
          });
        });
      }
    });
    tourClose.addEventListener('click', function () {
      let panorama = document.querySelector('.tour__panorama'),
        headerBurger = document.querySelector('.header_burger');

      panorama.classList.remove('active');
      headerBurger.classList.remove('hidden');
    });
    infoModalOpen.addEventListener('click', function () {
      let infoModal = document.querySelector('.tour__nav .info-modal');
      infoModal.classList.add('active');
    });
    infoModalClose.addEventListener('click', function () {
      let infoModal = document.querySelector('.tour__nav .info-modal');
      infoModal.classList.remove('active');
    });
  }
});
