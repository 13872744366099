document.addEventListener("DOMContentLoaded", function () {
    let catalogSliders = document.querySelectorAll('.js-catalog-item__slider'),
        catalogModalWrap = document.getElementById('get-offer'),
        catalogModal = new Modal(catalogModalWrap, {
            backdrop: 'static'
        }),
        modalTriggers = document.querySelectorAll('a[data-toggle="modal"][data-target="get-offer"]'),
        modalInputs = document.querySelectorAll('.get-offer input');

    for (let catalogSlider of catalogSliders) {
        let nextButton = catalogSlider.parentNode.querySelector('.catalog-item__slider-buttons > .next'),
            prevButton = catalogSlider.parentNode.querySelector('.catalog-item__slider-buttons > .prev'),
            flktySlider = new Flickity(catalogSlider, {
                prevNextButtons: false,
                pageDots: true,
                draggable: true,
                wrapAround: true,
                on: {
                    ready: function () {
                        nextButton.addEventListener('click', function (event) {
                            event.preventDefault();
                            flktySlider.next();
                            return false;
                        });

                        prevButton.addEventListener('click', function (event) {
                            event.preventDefault();
                            flktySlider.previous();
                            return false;
                        });
                    }
                }
            });
    }

    for (let modalTrigger of modalTriggers) {
        modalTrigger.addEventListener('click', function (event) {
            catalogModal.show();
            return false;
        });
    }

    for (let modalInput of modalInputs) {
        modalInput.addEventListener('change', function () {
            let parentElement = this.parentElement,
                inputLabel = parentElement.querySelector('label');

            if (this.value != '') {
                inputLabel.classList.add('active');
            } else {
                inputLabel.classList.remove('active');
            }
        });
    }
});