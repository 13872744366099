document.addEventListener('DOMContentLoaded', function(event) {
    let tabsNav = document.querySelectorAll('.js-production__inner-nav'),
        tabList = document.querySelector('.production-inner .production-catalog__list');

    if(window.innerWidth <= 580){
        tabList.addEventListener('click', function(){
            this.classList.toggle('opened');
        });
    }

    for (let tabNav of tabsNav){
        tabNav.addEventListener('click', function(){
            let tabTitle = this.getAttribute('data-nav'),
                tab = document.querySelector('[data-tab="' + tabTitle + '"]'),
                activeNav = document.querySelector('.js-production__inner-nav.active'),
                activeTab = document.querySelector('.js-production-inner__tab.active');

            activeNav.classList.remove('active');
            activeTab.classList.remove('active');
            this.classList.add('active');
            tab.classList.add('active');

            if(window.innerWidth <= 580) {
                let mainTab = tabList.querySelector('.js-production-catalog__item-main'),
                    tabText = this.querySelector('.production-catalog__item-name').innerText;

                mainTab.innerText = tabText;
            }
        });
    }
});