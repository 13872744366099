document.addEventListener("DOMContentLoaded", function () {
    let aboutSliderWrap = document.querySelector('.js-about-fs__slider'),
        sliderButtonOpen = document.querySelector('.js-about-fs__link'),
        sliderButtonClose = document.querySelector('.js-about-fs__slider-close'),
        aboutWrap = document.querySelector('.js-about-fs'),
        powerSlider = document.querySelector('.about-power__list');

    if(sliderButtonOpen != null) {
        sliderButtonOpen.addEventListener('click', function (event) {
            event.preventDefault();
            aboutWrap.classList.add('slider-opened');
            return false;
        });
    }

    if(window.innerWidth <= 1199 && powerSlider != null){
        let flktyPowerSlider = new Flickity(powerSlider, {
            prevNextButtons: false,
            pageDots: true,
            draggable: true,
            wrapAround: true,
        });
    }

    if(sliderButtonClose != null) {
        sliderButtonClose.addEventListener('click', function (event) {
            event.preventDefault();
            aboutWrap.classList.remove('slider-opened');
            return false;
        });
    }

    if (aboutSliderWrap != null) {
        let sliderButtonNext = document.querySelector('.about-fs__slider-buttons .next'),
            sliderButtonPrev = document.querySelector('.about-fs__slider-buttons .prev'),
            aboutSlider = new Flickity(aboutSliderWrap, {
                prevNextButtons: false,
                pageDots: true,
                draggable: true,
                wrapAround: true,
                on: {
                    ready: function () {
                        sliderButtonNext.addEventListener('click', function (event) {
                            event.preventDefault();
                            aboutSlider.next();
                            return false;
                        });

                        sliderButtonPrev.addEventListener('click', function (event) {
                            event.preventDefault();
                            aboutSlider.previous();
                            return false;
                        });
                    }
                }
            });
    }
});