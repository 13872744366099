var keys = [32, 33, 34, 35, 36, 37, 38, 39, 40];

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault)
    e.preventDefault();
  e.returnValue = false;
}

function keydown(e) {
  for (var i = keys.length; i--;) {
    if (e.keyCode === keys[i]) {
      preventDefault(e);
      return;
    }
  }
}

function wheel(e) {
  preventDefault(e);
}

function disable_scroll() {
  // if (window.addEventListener) {
  //   window.addEventListener('DOMMouseScroll', wheel, false);
  // }
  // window.onmousewheel = document.onmousewheel = wheel;
  // document.onkeydown = keydown;
  // disable_scroll_mobile();
    document.querySelector('body').style.overflowY = 'hidden';
}

function enable_scroll() {
  // if (window.removeEventListener) {
  //   window.removeEventListener('DOMMouseScroll', wheel, false);
  // }
  // window.onmousewheel = document.onmousewheel = document.onkeydown = null;
  // enable_scroll_mobile();
    document.querySelector('body').style.overflowY = 'scroll';

}

function disable_scroll_mobile() {
  document.addEventListener('touchmove', preventDefault, false);
}

function enable_scroll_mobile() {
  document.removeEventListener('touchmove', preventDefault, false);
}

document.addEventListener('DOMContentLoaded', function (event) {
  var burgerButton = document.querySelector('.js-header__burger'),
    container = document.querySelector('.js-content');

  window.addEventListener('scroll', function () {
    if (window.scrollY > 0) {
      document.querySelector('.js-header').classList.add('active');
      document.querySelector('.header_burger').classList.add('active');
    } else {
      document.querySelector('.js-header').classList.remove('active');
      document.querySelector('.header_burger').classList.remove('active');
    }
  });


  burgerButton.addEventListener('click', function () {
    if (window.innerWidth > 1199) {
      var windowWidth = window.innerWidth,
        bodyOffsetTop = document.querySelector('body').getBoundingClientRect().top;

      var tl = new TimelineMax({
        onComplete: function () {
          new TimelineMax();
        }
      });

      if (this.classList.contains('is-active')) {
        this.classList.remove('is-active');
        var currentScreen = document.querySelector('.js-menu'),
          newScreen = document.querySelector('.js-content-wrap'),
          header = document.querySelector('.js-header');
        windowWidth = -windowWidth;

        var my = tl
          .set(newScreen, {zIndex: 100})
          .set(currentScreen, {zIndex: 100})
          .fromTo(currentScreen, 0.5, {x: 0}, {x: -windowWidth}, 0)
          .fromTo(newScreen, 0.5, {x: windowWidth}, {x: 0}, 0)
          .fromTo(header, 0, {y: 0}, {y: -bodyOffsetTop}, 0)
          .set(currentScreen, {zIndex: 'auto'});

        setTimeout(function () {
          newScreen.style.transform = '';
          header.style.transform = '';
          enable_scroll();
        }, 600);
      } else {
        this.classList.add('is-active');
        var currentScreen = document.querySelector('.js-content-wrap'),
          newScreen = document.querySelector('.js-menu');

        disable_scroll();

        var my = tl
          .set(newScreen, {zIndex: 100})
          .set(currentScreen, {zIndex: 100})
          .fromTo(newScreen, 0, {y: 0}, {y: -bodyOffsetTop})
          .fromTo(currentScreen, 0.5, {x: 0}, {x: -windowWidth}, 0)
          .fromTo(newScreen, 0.5, {x: windowWidth}, {x: 0}, 0)
          .set(currentScreen, {zIndex: 'auto'});
      }
    } else {
      let menu = document.querySelector('.js-menu'),
        body = document.querySelector('body');

      menu.classList.toggle('active');
      body.classList.toggle('overflow');
    }
  });
});
